<template>
  <div class="register-step2">
    <n-loader :loading="$var('load')" />
    <top-bar back backroute="profile" :title="$app.secure.clean($n.t('10.4'))" is-auth />
    <div class="container">
      <n-input :title="$app.secure.clean($n.t('3.26'))" @input="isNew()" v-bind="$form.input('moreAddress')"></n-input>
      <n-button v-show="showBtn" wide color="yellow" @click="save"><t name="16.7" /></n-button>
      <div class="wrap">
        <div v-for="item in cities" :key="item.id" class="">
          <div class="item" @click="open(item.id)">
            <div class="left">
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="right">
              <div class="right papa"><n-icon icon="right-arrow" /></div>
            </div>
          </div>
          <div v-if="item.id === opened">
            <div v-for="child in item.children"
                 :key="child.id" class="item child" @click="selectCity(child.id)">
              <div class="left">
                <div class="title">{{ child.name }}</div>
              </div>
              <div class="right">
                <div class="right"><n-icon v-if="user.cityId === child.id" icon="selected" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageFinishRegister',
  data() {
    return {
      logo, img,
      cities: [],
      user: $app.auth.user(),
      lastAddress: $app.auth.user().moreAddress || '',
      showBtn: false,
      opened: null,
    }
  },
  created() {
    this.loadCities()
    $app.store.action('app.updateUser').finally(() => {
      this.user = $app.auth.user()
      this.$form.init(this.user)
    })
  },
  methods: {
    open(id) {
      if (this.opened === id) {
        this.opened = null
      } else {
        this.opened = id
      }
    },
    isNew() {
      this.showBtn = this.lastAddress !== this.$form.get('moreAddress');
    },
    save() {
      this.$var('load', true)
      $api.user.edit(this.user.id, {moreAddress: this.$form.get('moreAddress')}).then(() => {
        $app.store.action('app.updateUser')
        this.showBtn = false
      }).finally(() => {
        this.$var('load', false)
      })
    },
    selectCity(cityId) {
      this.$var('load', true)
      $api.user.edit(this.user.id, { cityId: cityId, }).then(() => {
        $app.store.action('app.updateUser').finally(() => {
          this.user = $app.auth.user()
          this.$var('load', false)
        })
      }).catch(() => {
        this.$var('load', false)
      })
    },
    loadCities() {
      this.$var('load', true)
      $api.cities.get().with('children').then((response) => {
        this.cities = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2{
  padding-top: 20px;
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .wrap {
    margin-top: 20px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
      padding: 5px;
      margin-bottom: 10px;
      min-height: 44px;
      &.child {
        padding-left: 25px;
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        &.papa {
          transform: rotate(90deg);
        }
      }
      .left {
        display: flex;
        align-items: center;
        justify-content: center;

        .title{
          font-weight: 400;
          margin-bottom: 0;
          font-size: 18px;
          color: #102E4F;
        }
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }
  .content {
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input{
    margin-bottom: 16px;
  }

  .n-button{
    position: relative;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
